// src/App.js
import './App.css';
import './Donate.css';
import { StompSessionProvider } from "react-stomp-hooks";
import React from "react";
import { useURLParams } from './hooks/useURLParams';
import SubscribingComponent from './features/subscriptions/SubscribingComponent';

const App = () => {
    const { userId, widgetType } = useURLParams();

    if (!userId) {
        return (
            <div className="error-page">
                <img src="/images/error.webp" alt="Error" className="error-image" />
                <h2 className="error-title">Invalid or Missing Parameter</h2>
                <p className="error-message">
                    Please provide a valid username in the URL parameters to continue.
                </p>
            </div>
        );
    }

    return (
        <StompSessionProvider
            url={`${process.env.REACT_APP_BACKEND_URL}/donate`}
            connectHeaders={{
                'username': userId,
                'widgetType': widgetType
            }}
        >
            <SubscribingComponent username={userId} />
        </StompSessionProvider>
    );
};

export default App;
